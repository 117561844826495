






















































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import businessStoreModule from "@/store/modules/business";
import roleStoreModule from "@/store/modules/role";
import { Role } from "@/types";
import { setAuth } from "@/util/auth";

const { mapActions: businessActions } =
  createNamespacedHelpers("BUSINESS_LIST");

const { mapActions: roleActions, mapGetters: roleGetters } =
  createNamespacedHelpers("ROLE_LIST");

export default Vue.extend<any, any, any, any>({
  name: "SelectBusiness",
  data: () => ({
    dialog: false,
    valid: false,
    businessName: "",
    businessEmail: "",
    businessTypes: [
      "Beauty",
      "Travel",
      "Activities",
      "Wellness",
      "Hair",
      "Barber",
      "Fitness",
      "Medi-aesthetics",
      "Event planning",
      "General services",
    ],
    businessType: undefined as undefined | string,
    nameRules: [(v: string) => !!v || "Name is required"],
    typeRules: [(v: string) => !!v || "Type is required"],
    userId: "",
    page: 1,
    activeRole: null as unknown as Role,
  }),
  created() {
    this.$store.dispatch("getLoggedInUser").then((role) => {
      if (role) {
        this.userId = role.user._id;
        this.fetchRoleList(`?userId=${role.user._id}`);
      }
    });
  },
  computed: {
    ...roleGetters(["rolePage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  watch: {
    page: "fetchRoles",
    role() {
      if (this.role) {
        this.activeRole = this.role;
      }
    },
  },
  methods: {
    ...businessActions(["createBusiness"]),
    ...roleActions(["fetchRoleList"]),
    selectRole(role: Role) {
      if (!role.business.vendorPlan) {
        this.$router.push(`/sign-up/plan/${role.business._id}`);
        return;
      }
      localStorage.setItem("roleId", role._id);
      localStorage.setItem("businessId", role.business._id);

      const path = (this.$route.query.redirectUrl as string) || "/";

      setAuth({ url: path });
    },
    validateForm() {
      if (!this.valid) return;

      this.createBusiness({
        name: this.businessName,
        type: this.businessType?.toLowerCase(),
        userId: this.userId,
        email: this.businessEmail,
      }).then((business) => {
        if (business) {
          this.dialog = false;
          // this.$router.push({
          //   path: `/sign-up/plan/${business._id}`,
          // });
        }
      });
    },
    fetchRoles() {
      this.fetchRoleList(`?userId=${this.userId}&page=${this.page}&limit=10`);
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("BUSINESS_LIST")) {
      this.$store.registerModule("BUSINESS_LIST", businessStoreModule);
    }
    if (!this.$store.hasModule("ROLE_LIST")) {
      this.$store.registerModule("ROLE_LIST", roleStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("BUSINESS_LIST");
    this.$store.unregisterModule("ROLE_LIST");
  },
});
